<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          ClassRooms
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.grade"
          placeholder="Grade"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.gradeList"
            :key="item.value"
            :label="item.description"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.term"
          placeholder="Term"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.termList"
            :key="item.value"
            :label="item.description"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          v-model="query.name"
          placeholder="Room Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon> <plus /> </el-icon>
          Create a ClassRoom
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'StartDate', order: 'DESC' }"
      >
        <el-table-column
          prop="name"
          label="Name"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Name"
        ></el-table-column>
        <el-table-column
          prop="studentCount"
          label="Students"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="lessonCount"
          label="Lessons"
          width="68"
        ></el-table-column>
        <el-table-column
          prop="grade"
          label="Grade"
          width="80"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Grade"
        ></el-table-column>
        <el-table-column prop="term" label="Term" width="70"></el-table-column>
        <el-table-column
          prop="startDate"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="StartDate"
          label="Start Date"
          width="100"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          prop="endDate"
          label="End Date"
          width="90"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Status"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="ActiveStatus"
          width="120"
        >
          <template #default="scope">
            <el-tag
              class="ml-2"
              :type="showClassRoomActiveStatusTagType(scope.$index, scope.row)"
            >
              {{ scope.row.activeStatusText }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          width="120"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column label="Operation" width="280" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button
              type="text"
              @click="manageStudents(scope.$index, scope.row)"
            >
              <el-icon>
                <user-filled />
              </el-icon>
              Students
            </el-button>
            <el-button
              type="text"
              @click="manageLessons(scope.$index, scope.row)"
            >
              <el-icon>
                <notebook />
              </el-icon>
              Lessons
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" width="90%">
        <el-form
          ref="mainForm"
          :model="form"
          :rules="rules"
          label-width="120px"
        >
          <el-form-item prop="name" label="Room Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="grade" label="Grade">
            <el-select v-model="form.grade" placeholder="Please select...">
              <el-option
                v-for="item in options.gradeList"
                :key="item.value"
                :label="item.description"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="term" label="Term">
            <el-select v-model="form.term" placeholder="Please select...">
              <el-option
                v-for="item in options.termList"
                :key="item.value"
                :label="item.description"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Date Range">
            <el-date-picker
              v-model="startAndEndDate"
              type="daterange"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :default-value="[new Date(), new Date()]"
              @calendar-change="handleDateRangeSelection"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Description">
            <el-input
              type="textarea"
              rows="5"
              v-model="form.description"
            ></el-input>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Normal" value="Normal"></el-option>
              <el-option label="Draft" value="Draft"></el-option>
              <el-option label="Deleted" value="Deleted"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="el-dialog__footer">
          <el-button @click="editVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveEdit">Save</el-button>
        </div>

        <el-tabs v-if="editMode" v-model="activeName" :lazy="true">
          <el-tab-pane :label="getAddStudentTabTitle(form)" name="studentsTab">
            <manage-students-in-class-room :classRoom="currentClassRoom" />
          </el-tab-pane>
          <el-tab-pane :label="getAddLessonTabTitle(form)" name="lessonsTab">
            <manage-lessons-in-class-room :classRoom="currentClassRoom" />
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog
        :title="manageStudentsDialogTitle"
        v-model="showManageStudentsDialog"
        width="90%"
      >
        <manage-students-in-class-room
          :classRoom="currentClassRoom"
          :destroy-on-close="true"
        />
      </el-dialog>

      <el-dialog
        :title="manageLessonsDialogTitle"
        v-model="showManageLessonsDialog"
        width="90%"
      >
        <manage-lessons-in-class-room
          :classRoom="currentClassRoom"
          :destroy-on-close="true"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";
import ManageStudentsInClassRoom from "./ManageStudentsInClassRoom.vue";
import ManageLessonsInClassRoom from "./ManageLessonsInClassRoom.vue";

export default {
  components: { ManageStudentsInClassRoom, ManageLessonsInClassRoom },
  name: "classRoomList",
  data() {
    return {
      controllerUrl: "/classRoom",
      query: {
        roomId: null,
        name: null,
        grade: null,
        term: null,
        orderBy: "ActiveStatus",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
        termList: [],
        scopeList: [],
        knowledgePointList: [],
      },
      tableData: [],
      activeName: "studentsTab",
      dialogTitle: "",
      currentClassRoom: null,
      editVisible: false,
      editMode: false,
      showManageStudentsDialog: false,
      manageStudentsDialogTitle: "",
      showManageLessonsDialog: false,
      manageLessonsDialogTitle: "",
      startAndEndDate: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      rules: {
        name: [
          {
            required: true,
            message: "Please input Class Name",
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: "Please select Class Grade",
            trigger: "change",
          },
        ],
        term: [
          {
            required: true,
            message: "Please select Class Term",
            trigger: "change",
          },
        ],
        startAndEndDate: [
          {
            required: true,
            message: "Please select start and end of date for this class",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select Class status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;

    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDateRangeSelection(dateRange) {
      console.log("handleDateRangeSelection", arguments);
      this.form.startDate = dateRange[0];
      this.form.endDate = dateRange[1];
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item？", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.roomId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
              //this.loadData();
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    handleEdit(index, row) {
      console.log("handleEdit", arguments);
      this.currentClassRoom = row;
      this.dialogTitle = "Edit";
      this.idx = index;
      this.form = row;
      this.editMode = true;

      this.startAndEndDate = [this.form.startDate, this.form.endDate];

      this.editVisible = true;
    },
    manageStudents(index, row) {
      console.log("manageStudents", arguments);
      this.currentClassRoom = row;
      this.manageStudentsDialogTitle = `Manage Students for the class room '${this.currentClassRoom.name}'`;
      this.showManageStudentsDialog = true;
    },
    manageLessons(index, row) {
      this.currentClassRoom = row;
      this.manageLessonsDialogTitle = `Manage Lessons for the class room '${this.currentClassRoom.name}'`;
      this.showManageLessonsDialog = true;
    },
    handleCreate() {
      this.form = { grade: 6, term: 1, status: "Normal" };
      this.dialogTitle = "New";
      this.editVisible = true;
      this.editMode = false;
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.form.roomId && this.form.roomId.length === 22) {
            putData(this.controllerUrl, this.form.roomId, this.form).then(
              (res) => {
                if (res.result && res.code === "200") {
                  this.editVisible = false;
                  this.$message.success("Update successfully");
                } else {
                  this.$message.error(
                    "Update failed, error message: " + res.message
                  );
                }
              }
            );
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error(
                  "Create failed, error message: " + res.message
                );
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    getAddStudentTabTitle(row) {
      return `Students(${row.studentCount})`;
    },
    getAddLessonTabTitle(row) {
      return `Lessons(${row.lessonCount})`;
    },
    showClassRoomActiveStatusTagType(index, row) {
      if (row.activeStatus === 2048) {
        return "";
      }
      if (row.activeStatus === 1) {
        return "info";
      }
      return "success";
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>

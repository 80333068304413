<template>
  <div class="container">
    <div class="handle-box">
      <el-input
        v-model="query.lessonName"
        placeholder="Lesson Name"
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-button type="primary" @click="handleSearch">
        <el-icon>
          <search />
        </el-icon>
        Search
      </el-button>
      <el-button
        type="primary"
        @click="openImportLessonDialog"
        style="float: right"
      >
        <el-icon>
          <plus />
        </el-icon>
        Import Lessons
      </el-button>
    </div>
    <el-table
      :data="tableData"
      border
      class="table"
      ref="multipleTable"
      header-cell-class-name="table-header"
      @sort-change="changeSort"
      :default-sort="{ prop: 'DisplayOrder', order: 'ascending' }"
    >
      <el-table-column
        prop="displayOrder"
        label="Display Order"
        sortable="true"
        width="120"
      ></el-table-column>
      <el-table-column prop="lessonName" label="Lesson Name"></el-table-column>
      <el-table-column
        prop="createdTime"
        sortable="true"
        :formatter="$tableCellFormatter.formatDate"
        label="Created Time"
        width="120"
      ></el-table-column>
      <el-table-column label="Operation" align="center" width="100">
        <template #default="scope">
          <el-button
            type="text"
            class="red"
            @click="handleDelete(scope.$index, scope.row)"
          >
            <el-icon>
              <delete />
            </el-icon>
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="itemTotal"
        :page-count="pageTotal"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
  <el-dialog
    :title="dialogTitle"
    v-model="showLessonSelectionDialog"
    width="80%"
  >
    <lessons-selection :initData="{ confirmFunction: handleImportLessons }" />
  </el-dialog>
</template>

<script>
import { getData, postData, putData } from "../../service/api";
import LessonsSelection from "./LessonsSelection.vue";

export default {
  name: "manageLessonsInClassRoom",
  components: { LessonsSelection },
  props: {
    classRoom: Object,
  },
  data() {
    return {
      controllerUrl: "/classRoom",
      query: {
        roomId: null,
        lessonId: null,
        lessonName: null,
        orderBy: "DisplayOrder",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 50,
      },
      options: {
        gradeList: [],
      },
      currentClassRoom: null,
      showLessonSelectionDialog: false,
      tableData: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  watch: {
    classRoom(newValue) {
      if (newValue) {
        if (newValue !== this.currentClassRoom) {
          this.initAndLoadData(newValue);
        }
      }
    },
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.initAndLoadData(this.classRoom);
  },
  methods: {
    initAndLoadData(classRoom) {
      this.currentClassRoom = classRoom;
      if (this.currentClassRoom) {
        this.query.roomId = this.currentClassRoom.roomId;
        this.loadData();
      }
    },
    loadData() {
      getData(
        `${this.controllerUrl}/getLessonsInClassRoom/${this.query.roomId}`,
        this.query
      ).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    loadClassRoom() {
      getData(`${this.controllerUrl}/${this.query.roomId}`).then((res) => {
        let resData = res.result;
        this.currentClassRoom.lessonCount = resData.lessonCount;
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    openImportLessonDialog() {
      this.showLessonSelectionDialog = true;
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to remove the lesson", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          let lessonIdList = [row.lessonId];
          putData(
            `${this.controllerUrl}/removeLessonsFromClassRoom/`,
            this.query.roomId,
            lessonIdList
          ).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Lessons removed successfully");
              this.loadClassRoom();
              this.loadData();
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    async handleImportLessons(lessonList) {
      try {
        let lessonIdList = [];
        let lessonNameList = [];
        if (lessonList && lessonList.length > 0) {
          for (let i = 0; i < lessonList.length; i++) {
            lessonIdList.push(lessonList[i].lessonId);
            lessonNameList.push(lessonList[i].name);
          }
        }
        await this.$confirm(
          `Are you sure to import the lessons: ${lessonNameList}`,
          "Prompt",
          {
            type: "warning",
          }
        );

        let res = await postData(
          `${this.controllerUrl}/addLessonsIntoClassRoom/${this.query.roomId}`,
          lessonIdList
        );
        console.log(
          "ManageLessonsInClassRoom.handleImportLessons",
          lessonIdList,
          res
        );

        if (res.result && res.code === "200") {
          this.$message.success("Import lessons successfully");
          this.showLessonSelectionDialog = false;
          this.loadClassRoom();
          this.loadData();
        } else {
          this.$message.error("Import failed, error message: " + res.message);
        }

        return res;
      } catch {
        // ignore
      }
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>

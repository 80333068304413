<template>
  <div class="container">
    <div class="handle-box">
      <el-input
        v-model="query.name"
        placeholder="Lesson Name"
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-select
        v-model="query.grade"
        placeholder="Grade"
        class="mr10"
        :clearable="true"
      >
        <el-option
          v-for="item in options.gradeList"
          :key="item.value"
          :label="item.description"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-model="query.term"
        placeholder="Term"
        class="mr10"
        :clearable="true"
      >
        <el-option
          v-for="item in options.termList"
          :key="item.value"
          :label="item.description"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="handleSearch">
        <el-icon>
          <search />
        </el-icon>
        Search
      </el-button>
      <div style="float: right">
        <el-button type="primary" @click="clearSelection">
          Clear selection
        </el-button>
        <el-button type="primary" @click="handleActionForSelectedLessons">
          <el-icon>
            <plus />
          </el-icon>
          {{ handleSelectedButtonText }}
        </el-button>
      </div>
    </div>
    <div class="selectedLessonsTag">
      <el-tag v-for="lesson in lessonList" :key="lesson.id" type="info">
        {{ lesson.name }}
      </el-tag>
    </div>
    <el-table
      :data="tableData"
      border
      class="table"
      ref="multipleTable"
      header-cell-class-name="table-header"
      row-key="lessonId"
      @sort-change="changeSort"
      :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      @selection-change="handleLessonSelectionChange"
    >
      <el-table-column type="selection" width="55" :reserve-selection="true" />
      <el-table-column prop="name" label="Lesson Name"></el-table-column>
      <el-table-column prop="grade" label="Grade" width="80"></el-table-column>
      <el-table-column prop="term" label="Term" width="80"></el-table-column>
      <el-table-column
        prop="createdTime"
        sortable="true"
        :formatter="$tableCellFormatter.formatDate"
        label="Created Time"
        width="120"
      ></el-table-column>
      <el-table-column label="Operation" width="100" align="center">
        <template #default="scope">
          <el-button
            type="text"
            @click="handleActionForCurrentLesson(scope.$index, scope.row)"
          >
            <el-icon> <circle-plus /> </el-icon>
            {{ handleRowButtonText }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="itemTotal"
        :page-count="pageTotal"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "lessonsSelection",
  props: {
    initData: {
      confirmFunction: Function,
    },
  },
  data() {
    return {
      controllerUrl: "/lesson",
      query: {
        name: null,
        grade: null,
        term: null,
        orderBy: null,
        orderDirection: null,
        pageIndex: 1,
        pageSize: 10,
      },
      options: {
        gradeList: [],
        termList: [],
        scopeList: [],
        knowledgePointList: [],
      },
      handleSelectedButtonText: "Import",
      handleRowButtonText: "Confirm",
      lessonList: [],
      tableData: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;
    this.options.scopeList = this.$appSetting.globalConfig.scopeList;
    this.options.knowledgePointList = this.$appSetting.globalConfig.knowledgePointList;

    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleActionForSelectedLessons() {
      let lessonList = this.lessonList;
      console.log("handleActionForSelectedLessons", lessonList);
      this.handleLessons(lessonList);
    },
    handleActionForCurrentLesson(index, row) {
      let lessonList = [row];
      console.log("handleActionForCurrentLesson", lessonList);
      this.handleLessons(lessonList);
    },
    handleLessons(lessonList) {
      this.initData.confirmFunction(lessonList);
    },
    clearSelection() {
      this.lessonList = [];
      this.$refs.multipleTable.clearSelection();
    },
    handleLessonSelectionChange(rows) {
      if (this.tableData && this.tableData.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          let index = this.lessonList.indexOf(this.tableData[i]);
          if (index >= 0) {
            this.lessonList.splice(index, 1);
          }
        }
      }
      if (rows && rows.length) {
        for (let i = 0; i < rows.length; i++) {
          if (this.lessonList.indexOf(rows[i]) >= 0) continue;
          this.lessonList.push(rows[i]);
        }
      }
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.selectedLessonsTag {
  margin: 20px 0;
}
.selectedLessonsTag .el-tag {
  margin: 4px;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
